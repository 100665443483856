export interface IWalletable {
  name: string;
}

export type TWalletSort = (a: IWalletable, b: IWalletable) => -1 | 1 | 0;

export const walletSort: TWalletSort = (a, b) => {
  if (a.name.toLowerCase() === 'gtrump') {
    return -1;
  }
  if (b.name.toLowerCase() === 'gtrump') {
    return 1;
  }
  return 0;
};
