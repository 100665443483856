import { IVideoTime } from '~/types/sanity-objects';

export interface ISeries {
  slug: string;
  episodes: IEpisode[];
}

export interface IEpisode {
  slug: string;
  projectSlug: string;
  title: string;
  synopsis: string;
  videoSlug: string;
  heroImage: string;
  runTime?: IVideoTime;
  completionTime?: IVideoTime;
  canSuggestWatching?: boolean;
  subtitle?: string;
  restrictionId?: string;
}

export enum EpisodeAction {
  START = 'start',
  RESUME = 'resume',
}

export interface IEpisodeWatchSuggestion {
  episodeAction: EpisodeAction;
  episodeNumber: number;
}

export interface IEpisodeWatchProgress {
  secondsRemaining: number;
  percentageWatched: number;
  watched: boolean;
}
